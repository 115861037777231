import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "",
      name: "HOME",
      component: () => import("@/views/pages/Base"),
      children: [
        {
          path: "/add-event",
          name: "Add Event",
          component: () => import("@/views/pages/events/AddEvent.vue"),
        },
        {
          path: "/edit-event/:id",
          name: "Edit Event",
          component: () => import("@/views/pages/events/AddEvent.vue"),
        },
        {
          path: "/events",
          name: "Events",
          component: () => import("@/views/pages/events/Events.vue"),
        },
        {
          path: "/event/:id",
          name: "Event Details",
          component: () => import("@/views/pages/events/EventDetails.vue"),
        },
        {
          path: "/add-doctor",
          name: "Add Doctor",
          component: () => import("@/views/pages/doctors/AddDoctor.vue"),
        },
        {
          path: "/doctors",
          name: "Doctors",
          component: () => import("@/views/pages/doctors/Doctors.vue"),
        },
        {
          path: "/add-question",
          name: "Add Question",
          component: () => import("@/views/pages/questions/AddQuestion.vue"),
        },
        {
          path: "/questions",
          name: "Questions",
          component: () => import("@/views/pages/questions/Questions.vue"),
        },
        {
          path: "event-details-reports",
          name: "Event Details Reports",
          component: () =>
            import("@/views/pages/reports/EventDetailsReport.vue"),
        },
        {
          path: "/questionnaire/events/:eventId/doctors/:doctorId/details",
          name: "Questionnaire Details",
          component: () =>
            import("@/views/pages/reports/QuestionnaireDetails.vue"),
        },
        {
          path: "/add-user",
          name: "Add User",
          component: () => import("@/views/pages/users/AddUser.vue"),
        },
        {
          path: "/users",
          name: "Users",
          component: () => import("@/views/pages/users/Users.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/Login"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/views/pages/auth/Register"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue"),
    },
  ],
  mode: "history",
});
