// EGYPT
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
    EVENTS: "المعارض",
    ADD_EVENT: "اضافة معرض",
    QUESTIONS: "الاسئلة",
    ADD_QUESTION: "اضافة سؤال",
    DOCTORS: "الاطباء",
    REPORTS: "التقارير",
    ADD_DOCTOR: "اضافة طبيب",
    ADD_USER: "اضافة مستخدم",
    USERS: "المستخدمين",
    HI: "اهلا",
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },
  COMMON: {
    SUBMIT: "حفظ",
    SHOW_DETAILS: "التفاصيل",
    ADD: "اضافة",
    VERIFY: "تأكيد",
    EDIT: "تعديل",
    DELETE: "حذف",
    BACK: "رجوع",
    YES: "نعم",
    NO: "لا",
    SKIP: "تخطى",
    WRITE_STH: "اكتب هنا...",
    SELECT_FRAME: "اختر اطار",
    TAKE_PHOTO: "من فضلك، قم بالتقاط الصورة",
    EXTRACT: "استخراج",
    THANK_YOU: "شكرا",
  },
  EVENT: {
    ADD_EVENT: "اضافة معرض",
    EDIT_EVENT: "اعادة معرض",
    EVENT_NAME: "اسم المعرض",
    EVENT_LOCATION: "مكان المعرض",
    EVENT_DATE: "تاريخ المعرض",
    EVENT_TEXT: "كلمة المقدمة",
    HAS_FORM: "اضافة Form",
    HAS_QUESTIONNAIRE: "اضافة استبيان",
    HAS_PHOTO: "اضافة صورة",
    HAS_VOUCHER: "اضافة قسيمة",
    EVENTS: "المعارض",
    IS_DOCTOR_REGISTERED: "هل انت من ضمن المسجلين لدى Novonordisk؟",
    FIELDS: [
      { key: "eventName", label: "اسم المعرض" },
      { key: "eventDate", label: "تاريخ المعرض" },
      { key: "location", label: "مكان المعرض" },
      { key: "show_details", label: "" },
    ],
    EVENT_DETAILS: {
      ADD_QUESTIONS: "اضافة سؤال",
      SELECT_LABEL_QUESTION: "ابدأ الكتابة للبحث عن السؤال",
      DOCTORS: "الاطباء",
      GO_BACK_TO_TABLES: "عودة للجدول",
      REGISTER_DOCTOR: "تسجيل الدكتور",
      SELECT_LABEL_DOCTOR: "ابدأالكتابة للبحث عن الدكتور",

      ASSIGNED_DOCTORS: "الاطباء المسجلين",
      REGISTER_NEW_DOCTOR: "تسجيل دكتور جديد",
      NO_DOCTORS_NOTE: "لا سوجد اطباء مسجلين فى هذا المعرض",
      TAKE_PHOTO: "التقط صورة",
      RECIEVE_VOUCHER: "استلم Voucher",
      FORM: {
        NAME: "الاسم",
        FIRST_NAME: "الاسم الاول*",
        LAST_NAME: "اسم العائلة*",
        EMAIL: "الايميل",
        ADDRESS: "العنوان",
        MOBILE_NUMBER: "الموبايل",
        SPECIALITY: "التخصص",
        GOVERNORATE: "المحافظة",
        BEEN_VISITED: "هل تمت دعوتك من قبل Novonordisk ?",
        ENTER_PHONE_NUMBER: "ادخل رقم الموبايل",
      },
      VERFICATION_MODAL_TITLE: "من فضلك ادخل رمز التعريف",
      ASSIGN_TO_EVENT: "تسجبل فى الحدث",
    },
  },
  QUESTION: {
    ADD_QUESTION: "اضافة سؤال",
    QUESTION: "السؤال",
    TYPE: "نوع السؤال",
    ANSWER: "الاجابة",
    OPTIONS: "الاختيارات",
    QUESTIONS: "الاسئلة",
    FIELDS: [
      { key: "question", label: "السؤال" },
      { key: "type", label: "النوع" },
      { key: "actions", label: "" },
    ],
  },
  DOCTOR: {
    FIELDS: [
      {
        key: "name",
        label: "الاسم",
      },
      {
        key: "email",
        label: "الايميل",
      },
      {
        key: "speciality",
        label: "التخصص",
      },
      {
        key: "governorate",
        label: "المحافظة",
      },
      {
        key: "mobileNo",
        label: "رقم الموبايل",
      },
      {
        key: "questionnaireSubmitted",
        label: "حالة الاستبيان",
      },
    ],
    GO_TO_QUESTIONNAIRE: "الذهاب للاستبيان",
    SUBMITTED: "مجاب",
  },
  REPORTS: {
    EVENT_DETAILS_REPORT: "تفاصيل الحدث",
    EVENT_DETAILS_REPORT_PAGE: {
      TITLE: "تقرير بالحدث",
      EVENT_PLACEHOLDER: "اختر الحدث",
      EVENT: "الحدث",
      DOCTOR: "الدكتور",
      SPECIALITY: "التخصص",
      GOVERNORATE: "المحافظة",
      DOCTOR_PLACEHOLDER: "اختر الدكتور",
      SPECIALITY_PLACEHOLDER: "اختر التخصص",
      GOVERNORATE_PLACEHOLDER: "اختر المحافظة",
      NOT_SUBMITTED: "غير مسلم",
      GO_TO_QUESTIONNAIRE: "اذهب الى الاستبيان",
    },
  },
  USER: {
    ADD_USER: "اضافة مستخدم",
    EDIT_USER: "تعديل المستخدم",
    USERNAME: "اسم المستخدم",
    EMAIL: "الايميل",
    PASSWORD: "كلمة السر",
  },

  PRINT_FORM: {
    PRINT: "طباعة",
    GO_TO_QUESTIONNAIRE: "الى الاسبيان",
    CONTACT_DETAILS: "بيانات الاتصال:",
  },

  FIELDS: {
    NAME: "الاسم",
    EMAIL: "الايميل",
    ADDRESS: "العنوان",
    MOBILE_NUMBER: "الموبايل",
    SPECIALITY: "التخصص",
    GOVERNORATE: "المحافظة",
    BEEN_VISITED: "هل تمت دعوتك من قبل Novonordisk ?",
  },
  DOCTOR_QUESTIONNAIRE: {
    TITLE: "الاستبيان",
  },
};
