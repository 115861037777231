import axios from "../../axios-auth";
import router from "../../router";
import Vue from "vue";

const state = {
  events: [],
  event: null,
  questionnaireQuestions: [],
  todaysEvents: [],
};

const mutations = {
  SET_EVENTS(state, payload) {
    state.events = payload;
  },
  SET_EVENT(state, payload) {
    state.event = payload;
  },
  SET_QUESTIONNAIRE_QUESTIONS(state, payload) {
    state.questionnaireQuestions = payload;
  },
  SET_TODAY_EVENTS(state, payload) {
    state.todaysEvents = payload;
  },
};

const actions = {
  saveEvent({ commit, rootGetters }, payload) {
    axios
      .post("/events", payload.data.event, {
        headers: {
          Authorization: "Bearer " + rootGetters.token,
        },
      })
      .then((res) => {
        const msg = "You successfully saved a new event";
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "success",
        };
        payload.vm.resetFields();
        payload.vm.loading = false;
        commit("makeToast", toast);
      })
      .catch((rej) => {
        const msg = "There was an error saving your calendar";
        payload.vm.loading = false;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "danger",
        };

        commit("makeToast", toast);
      });
  },
  updateEvent({ commit, rootGetters }, payload) {
    axios
      .post("/events/" + payload.id + "/edit", payload.data.event, {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        const msg = "You successfully updated a new event";
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "success",
        };
        payload.vm.resetFields();
        payload.vm.loading = false;
        router.push({ name: "Events" });
        commit("makeToast", toast);
      })
      .catch((rej) => {
        const msg = "There was an error updating your calendar";
        payload.vm.loading = false;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "danger",
        };

        commit("makeToast", toast);
      });
  },
  getEvents({ commit, rootGetters }) {
    axios
      .get("/events", {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        commit("SET_EVENTS", res.data);
      })
      .catch((err) => {});
  },
  getEvent({ commit, rootGetters }, payload) {
    axios
      .get("/events/" + payload.id, {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        commit("SET_EVENT", res.data);
      })
      .catch((err) => {});
  },
  getQuestionnaireQuestions({ commit, rootGetters }, payload) {
    axios
      .get("/events/" + payload.eventId + "/questions", {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        commit("SET_QUESTIONNAIRE_QUESTIONS", res.data);
      })
      .catch((err) => {});
  },
  getTodaysEvents({ commit, rootGetters }, payload) {
    axios
      .get("/events/event-exists-today", {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        const data = res.data;
        if (data.length > 0) {
          router.push({ name: "Event Details", params: { id: data[0].id } });
        } else {
          router.push({ name: "Events" });
        }

        commit("SET_TODAY_EVENTS", res.data);
      })
      .catch((err) => {});
  },
  deleteEvent({ commit, dispatch, rootGetters }, payload) {
    axios
      .delete("/events/" + payload.eventId, {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        const msg = "You successfully deleted a this event";
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "success",
        };
        commit("makeToast", toast);
        dispatch("getEvents");
      })
      .catch((err) => {
        const msg = "There was an error deleting your event";
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "danger",
        };

        commit("makeToast", toast);
      });
  },
};

const getters = {
  event(state) {
    return state.event;
  },
  events(state) {
    return state.events;
  },
  questionnaireQuestions(state) {
    return state.questionnaireQuestions;
  },
  todaysEvents(state) {
    return state.todaysEvents;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
