import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import event from "./modules/event";
import doctor from "./modules/doctor";
import question from "./modules/question";
import reports from "./modules/reports";
import user from "./modules/user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    event,
    doctor,
    question,
    reports,
    user,
  },
});
