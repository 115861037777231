<template>
  <div class="wrapper">
    <v-app>
      <router-view></router-view>
    </v-app>
  </div>
</template>

<style>
/* body {
  overflow: hidden;
  margin: 0;
}
.wrapper {
  transform: rotate(270deg);
  transform-origin: top left;
  top: 100vh;
  height: 100vw;
  width: 100vh;
  position: absolute;
} */
</style>

<style scoped lang="scss">
// ::v-deep .v-application--wrap {
//   height: 100vw;
//   width: 100vh;
// }
</style>

<style lang="scss">
// 3rd party plugins css
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "assets/plugins/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
// @import "assets/sass/print-form.scss";

// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
/*@import "assets/css/style.vue.rtl";*/

// Demo skins (demo1 only)
@import "assets/sass/global/integration/frameworks/vue/skins";

// @font-face {
//   font-family: helvetica;
//   src: url("~@/assets/media/fonts/helvetica_neue_1.tff") format("truetype");
// }

html[lang="ar"] {
  .kt-wrapper-full-width {
    padding-right: 0;
  }

  .kt-header-full-width {
    right: 0 !important;
  }

  .kt-aside--fixed .kt-wrapper {
    padding-left: 0;
    // padding-right: 0;
  }

  .kt-aside--enabled .kt-header.kt-header--fixed {
    left: 0;
    // right: 0;
  }
}

.kt-wrapper {
  background-color: white;
}

.btn i {
  padding-left: 0 !important;
}

.kt-content {
  padding: 0px 0 25px !important;
  margin-top: -33px !important;

  background: url("~@/assets/media/bg/background-1.svg"),
    url("~@/assets/media/bg/background-2.svg");
  background-position-y: top, bottom;
  background-position-x: right, left;
  background-repeat: no-repeat, no-repeat;
}

.kt-aside .kt-aside-menu {
  margin-top: 28px !important;
}

.kt-menu__link-icon {
  display: none !important;
}

.kt-aside {
  // background: url("~@/assets/media/bg/background-3.svg");
  // background-position-y: bottom;
  // background-repeat: no-repeat;
  // background-size: contain;
}

.kt-aside-dark .kt-aside,
.kt-brand-dark .kt-aside__brand,
.kt-aside-dark .kt-aside-menu {
  background-color: #21285f;
}

.kt-menu__link-text {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: white !important;
  font-family: "Helvetica Neue", sans-serif;
}

@import url("https://fonts.cdnfonts.com/css/helvetica-neue-9");

.helvetica {
  font-family: "Helvetica Neue", sans-serif;
}
</style>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/store/config.module";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { setDocumentDirectionPerLocale, setDocumentLang } from "./store/script";
import * as $ from "jquery";

export default {
  name: "MetronicVue",
  created() {
    this.CHECK_EXPIRATION_DATE();
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/common/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    this.checkIfRTL();
    this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }

        setDocumentLang(newLocale);

        setDocumentDirectionPerLocale(newLocale);
      },
      { immediate: true }
    );

    this.$watch(
      "$route",
      (oldRoute, newRoute) => {
        if (this.$route.name === "HOME") {
          if (this.roles.includes("ROLE_EVENT_MANAGER")) {
            this.getTodaysEvents();
          } else {
            this.$router.push({ name: "Events" });
          }
        }
      },
      { immediate: true }
    );
  },
  methods: {
    ...mapMutations({
      CHECK_EXPIRATION_DATE: "CHECK_EXPIRATION_DATE",
    }),
    ...mapActions({
      send: "send",
      getTodaysEvents: "getTodaysEvents",
    }),
    checkIfRTL() {
      if (this.$i18n.locale === "en") {
        $("#lang").attr(
          "href",
          process.env.BASE_URL + "assets/media/css/style.vue.css"
        );
        let file = document.createElement("link");
        file.type = "text/css";
        file.rel = "stylesheet";
        file.href = process.env.BASE_URL + "assets/media/css/style.vue.css";
        this.$vuetify.rtl = false;
        document.head.appendChild(file);
        // require("../public/assets/media/css/style.vue.css");
        // import "../public/assets/media/css/style.vue.css";
      } else if (this.$i18n.locale === "ar") {
        $("#lang").attr(
          "href",
          process.env.BASE_URL + "assets/media/css/style.vue.rtl.css"
        );
        let file = document.createElement("link");
        file.type = "text/css";
        file.rel = "stylesheet";
        file.href = process.env.BASE_URL + "assets/media/css/style.vue.rtl.css";
        document.head.appendChild(file);
        this.$vuetify.rtl = true;
        // require("../public/assets/media/css/style.vue.rtl.css");
      }
    },
  },
  computed: {
    ...mapGetters({
      roles: "roles",
    }),
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },
};
</script>
