import axios from "../../axios-auth";
import router from "../../router";
import Vue from "vue";

const state = {
  questionnaire: [],
  selectedEvent: null,
  selectedDoctor: null,
  showAssignedDoctorsTable: false,
  reportAssignedDoctors: [],
  selectedSpeciality: null,
  selectedGovernorate: null,
};

const mutations = {
  SET_QUESTIONNAIRE(state, payload) {
    state.questionnaire = payload;
  },
  SET_SELECTED_EVENT(state, payload) {
    state.selectedEvent = payload;
  },
  SET_SELECTED_DOCTOR(state, payload) {
    state.selectedDoctor = payload;
  },
  SET_ASSIGNED_DOCTORS_TABLE(state, payload) {
    state.showAssignedDoctorsTable = payload;
  },
  SET_REPORT_ASSIGNED_DOCTORS(state, payload) {
    state.reportAssignedDoctors = payload;
  },
  SET_SELECTED_SPECIALITY(state, payload) {
    state.selectedSpeciality = payload;
  },
  SET_SELECTED_GOVERNATE(state, payload) {
    state.selectedGovernorate = payload;
  },
};

const actions = {
  getDoctorQuestionniare({ commit, rootGetters }, payload) {
    axios
      .get(
        "/events/" +
          payload.eventId +
          "/doctors/" +
          payload.doctorId +
          "/questionnaire",
        { headers: { Authorization: "Bearer " + rootGetters.token } }
      )
      .then((res) => {
        commit("SET_QUESTIONNAIRE", res.data);
      })
      .catch((err) => {});
  },
};

const getters = {
  questionnaire(state) {
    return state.questionnaire;
  },
  selectedEvent(state) {
    return state.selectedEvent;
  },
  selectedDoctor(state) {
    return state.selectedDoctor;
  },
  showAssignedDoctorsTable(state) {
    return state.showAssignedDoctorsTable;
  },
  reportAssignedDoctors(state) {
    return state.reportAssignedDoctors;
  },
  selectedSpeciality(state) {
    return state.selectedSpeciality;
  },
  selectedGovernorate(state) {
    return state.selectedGovernorate;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
