import axios from "../axios-auth";
import router from "../router";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: localStorage.getItem("user"),
  isAuthenticated: localStorage.getItem("token") !== null ? true : false,
  token: localStorage.getItem("token") || null,
  username: localStorage.getItem("username") || null,
  roles: [],
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  token(state) {
    return state.token;
  },
  username(state) {
    return JSON.parse(state.user).username;
  },
  roles(state) {
    return JSON.parse(state.user).roles;
  },
};

const actions = {
  [LOGIN]({ commit }, payload) {
    axios
      .post("/auth/signin", payload.user_info)
      .then((res) => {
        commit("SET_AUTH", res.data);
        if (res.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(res.data));
        }
        localStorage.setItem("language", "en");

        commit("SET_INTERVAL_TIME", 120000);
        router.push({ name: "HOME" });
      })
      .catch((err) => commit(SET_ERROR, "Bad Credentials"));
  },
  [LOGOUT]() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("expiration-date");
  },
  [REGISTER]({ commit }, payload) {
    axios
      .post("/auth/signup", payload.user_info)
      .then((res) => {
        localStorage.setItem("language", "en");

        commit("SET_INTERVAL_TIME", 120000);
        const msg = "You successfully registered";
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "success",
        };
        payload.vm.resetForm();
        payload.vm.loading = false;
        commit("makeToast", toast);
        router.push({ name: "login" });
      })
      .catch((err) => {
        const msg = "There was an error in the register";
        payload.vm.loading = false;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "danger",
        };

        commit("makeToast", toast);
      })
      .finally(() => {
        payload.vm.submitting = false;
      });
  },
  [VERIFY_AUTH](context) {
    if (context.state.isAuthenticated) {
      return true;
    } else {
      return false;
    }
  },
  // [UPDATE_USER](context, payload) {
  //   const { email, username, password, image, bio } = payload;
  //   const user = { email, username, bio, image };
  //   if (password) {
  //     user.password = password;
  //   }

  //   return ApiService.put("user", user).then(({ data }) => {
  //     context.commit(SET_AUTH, data);
  //     return data;
  //   });
  // }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  SET_AUTH(state, user) {
    state.isAuthenticated = true;
    state.errors = {};
    state.token = user.accessToken;
    localStorage.setItem("token", user.accessToken);
    state.user = JSON.stringify(user);
    state.roles = user.roles;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
  },
  makeToast(state, payload) {
    payload.vm.$bvToast.toast(payload.msg, {
      title: "Message",
      autoHideDelay: 2000,
      appendToast: false,
      variant: payload.color,
    });
  },
  SET_INTERVAL_TIME(state, payload) {
    const currentTimeAsMs = Date.now();
    const adjustedTimeAsMs = currentTimeAsMs + 1000 * 60 * 60 * 24;

    const adjustedDateObj = new Date(adjustedTimeAsMs);
    localStorage.setItem("expiration-date", adjustedDateObj);
  },
  CHECK_EXPIRATION_DATE() {
    const expirationDate = localStorage.getItem("expiration-date");

    if (new Date(expirationDate).getTime() < new Date().getTime()) {
      localStorage.clear();
      router.push("login");
    }
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
