import axios from "../../axios-auth";
import router from "../../router";
import Vue from "vue";

const state = {
  questions: [],
  question: null,
  eventQuestions: [],
};

const mutations = {
  SET_QUESTIONS(state, payload) {
    state.questions = payload;
  },
  SET_QUESTION(state, payload) {
    state.question = payload;
  },
  SET_EVENT_QUESTIONS(state, payload) {
    state.eventQuestions = payload;
  },
};

const actions = {
  saveQuestion({ commit, rootGetters }, payload) {
    axios
      .post("/questions", payload.data.question, {
        headers: {
          Authorization: "Bearer " + rootGetters.token,
        },
      })
      .then((res) => {
        const msg = "You successfully saved a new question";
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "success",
        };
        payload.vm.resetFields();
        payload.vm.loading = false;
        commit("makeToast", toast);
      })
      .catch((rej) => {
        const msg = "There was an error saving your question";
        payload.vm.loading = false;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "danger",
        };

        commit("makeToast", toast);
      });
  },
  getQuestions({ commit, rootGetters }) {
    axios
      .get("/questions", {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        commit("SET_QUESTIONS", res.data);
      })
      .catch((err) => {});
  },
  addQuestionToEvent({ commit, dispatch, rootGetters }, payload) {
    axios
      .post("/events/" + payload.eventId + "/questions", payload.questionData, {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        const msg = "You successfully add a new question to this event";
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "success",
        };
        payload.vm.resetFields();
        payload.vm.loading = false;
        commit("makeToast", toast);
        payload.vm.resetFields();
        dispatch("getEventQuestions", { eventId: payload.eventId });
        // dispatch("getAssignedDoctors", { eventId: payload.eventId });
      })
      .catch((err) => {
        const msg = "There was an error adding your question";
        payload.vm.loading = false;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "danger",
        };

        commit("makeToast", toast);
      });
  },
  getEventQuestions({ commit, rootGetters }, payload) {
    axios
      .get("/events/" + payload.eventId + "/questions", {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => commit("SET_EVENT_QUESTIONS", res.data))
      .catch((err) => {});
  },
  deleteEventQuestion({ commit, dispatch, rootGetters }, payload) {
    axios
      .delete(
        "/events/" + payload.eventId + "/questions/" + payload.questionId,
        {
          headers: { Authorization: "Bearer " + rootGetters.token },
        }
      )
      .then((res) => {
        const msg = "You successfully deleted a question to this event";
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "success",
        };
        commit("makeToast", toast);
        dispatch("getEventQuestions", { eventId: payload.eventId });
      })
      .catch((err) => {
        const msg = "There was an error deleting your question";
        payload.vm.loading = false;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "danger",
        };

        commit("makeToast", toast);
      });
  },
  deleteQuestion({ commit, dispatch, rootGetters }, payload) {
    axios
      .delete("/questions/" + payload.id + "/delete", {
        headers: {
          Authorization: "Bearer " + rootGetters.token,
        },
      })
      .then((res) => {
        const msg = res.data;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "success",
        };
        commit("makeToast", toast);
        dispatch("getQuestions");
      })
      .catch((err) => {
        const msg = err.response.data;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "danger",
        };

        commit("makeToast", toast);
      });
  },
};

const getters = {
  question(state) {
    return state.question;
  },
  questions(state) {
    return state.questions;
  },
  eventQuestions(state) {
    return state.eventQuestions;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
