import axios from "../../axios-auth";
import router from "../../router";
import Vue from "vue";

const state = {
  users: [],
  user: null,
};

const mutations = {
  SET_USERS(state, payload) {
    state.users = payload;
  },
};

const actions = {
  saveUser({ commit, rootGetters }, payload) {
    axios
      .post("/users", payload.user, {
        headers: {
          Authorization: "Bearer " + rootGetters.token,
        },
      })
      .then((res) => {
        const msg = "You successfully saved a new user";
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "success",
        };
        payload.vm.resetFields();
        payload.vm.loading = false;
        payload.vm.submitting = false;
        commit("makeToast", toast);
      })
      .catch((rej) => {
        const msg = "There was an error saving your user";
        payload.vm.loading = false;
        payload.vm.submitting = false;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "danger",
        };

        commit("makeToast", toast);
      });
  },
  getUsers({ commit, rootGetters }) {
    axios
      .get("/users", {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => commit("SET_USERS", res.data))
      .catch((err) => {});
  },
};

const getters = {
  users(state) {
    return state.users;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
